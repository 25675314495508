import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${convertHexToRGBA(theme.color.primary.pure, 0.9)};

    z-index: 25;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `};
`;

export const Modal = styled.div`
  width: 90%;
  max-width: 465px;
  height: auto;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  color: rgba(49, 48, 48, 1);
  margin: 0;
  margin-top: 16px;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 21.28px;
  font-weight: 400;
  color: rgba(134, 133, 133, 1);
  padding: 0 20px;
  margin: 8px 0 24px;
`;

export const Link = styled.span`
  text-decoration: underline;
  color: rgba(24, 24, 24, 1);
  &:hover {
    cursor: pointer;
  }
`;
