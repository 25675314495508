import * as GlobalStyles from '@wisereducacao-npm/design-system-tokens/dist/esm/globals';
import { DefaultTheme, createGlobalStyle, css } from 'styled-components';

const spacing = {
  giant: GlobalStyles.SpacingGiant,
  huge: GlobalStyles.SpacingHuge,
  insetLg: GlobalStyles.SpacingInsetLg,
  insetMd: GlobalStyles.SpacingInsetMd,
  insetNano: GlobalStyles.SpacingInsetNano,
  insetQuarck: GlobalStyles.SpacingInsetQuarck,
  insetSm: GlobalStyles.SpacingInsetSm,
  insetXs: GlobalStyles.SpacingInsetXs,
  insetXxs: GlobalStyles.SpacingInsetXxs,
  lg: GlobalStyles.SpacingLg,
  md: GlobalStyles.SpacingMd,
  nano: GlobalStyles.SpacingNano,
  quarck: GlobalStyles.SpacingQuarck,
  sm: GlobalStyles.SpacingSm,
  xl: GlobalStyles.SpacingXl,
  xs: GlobalStyles.SpacingXs,
  xxl: GlobalStyles.SpacingXxl,
  xxs: GlobalStyles.SpacingXxs,
  xxxl: GlobalStyles.SpacingXxxl,
  xxxs: GlobalStyles.SpacingXxxs,
  xxxxs: GlobalStyles.SpacingXxxxs,
} as const;

const font = {
  size: {
    display: GlobalStyles.FontSizeDisplay,
    giant: GlobalStyles.FontSizeGiant,
    lg: GlobalStyles.FontSizeLg,
    md: GlobalStyles.FontSizeMd,
    sm: GlobalStyles.FontSizeSm,
    xl: GlobalStyles.FontSizeXl,
    xs: GlobalStyles.FontSizeXs,
    xxl: GlobalStyles.FontSizeXxl,
    xxs: GlobalStyles.FontSizeXxs,
    xxxl: GlobalStyles.FontSizeXxxl,
    xxxs: GlobalStyles.FontSizeXxxs,
  },
  weight: {
    bold: GlobalStyles.FontWeightBold,
    light: GlobalStyles.FontWeightLight,
    regular: GlobalStyles.FontWeightRegular,
    semiBold: GlobalStyles.FontWeightSemiBold,
  },
} as const;

const lineHeight = {
  default: GlobalStyles.LineHeightDefault,
  lg: GlobalStyles.LineHeightLg,
  md: GlobalStyles.LineHeightMd,
  sm: GlobalStyles.LineHeightSm,
  xl: GlobalStyles.LineHeightXl,
  xs: GlobalStyles.LineHeightXs,
  xxl: GlobalStyles.LineHeightXxl,
} as const;

const border = {
  radius: {
    circular: GlobalStyles.BorderRadiusCircular,
    lg: GlobalStyles.BorderRadiusLg,
    md: GlobalStyles.BorderRadiusMd,
    none: GlobalStyles.BorderRadiusNone,
    pill: GlobalStyles.BorderRadiusPill,
    sm: GlobalStyles.BorderRadiusSm,
    xs: GlobalStyles.BorderRadiusXs,
  },
  width: {
    hairline: GlobalStyles.BorderWidthHairline,
    heavy: GlobalStyles.BorderWidthHeavy,
    none: GlobalStyles.BorderWidthNone,
    thick: GlobalStyles.BorderWidthThick,
    thin: GlobalStyles.BorderWidthThin,
  },
} as const;

const opacity = {
  intense: GlobalStyles.OpacityLevelIntense,
  light: GlobalStyles.OpacityLevelLight,
  medium: GlobalStyles.OpacityLevelMedium,
  regular: GlobalStyles.OpacityLevelRegular,
  semiopaque: GlobalStyles.OpacityLevelSemiopaque,
  semitransparent: GlobalStyles.OpacityLevelSemitransparent,
} as const;

const shadow = {
  level1: GlobalStyles.ShadowLevel1,
  level2: GlobalStyles.ShadowLevel2,
  level3: GlobalStyles.ShadowLevel3,
  level4: GlobalStyles.ShadowLevel4,
} as const;

const breakpoints = {
  xxxs: '0px',
  xxs: '375px',
  xs: '420px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1400px',
} as const;

const GlobalStyle: any = createGlobalStyle`
  ${({ theme, showWppButton, isMozambiqueTheme }: any) => css`
    * {
      box-sizing: border-box;
    }

    body {
      background-color: ${theme.color.primary.pure};
      font-family: ${theme.style?.fontFamily};
      padding-bottom: 40px;

      .container-whatsapp-btn {
        display: ${showWppButton ? 'block' : 'none'};
      }

      .grecaptcha-badge {
        visibility: ${isMozambiqueTheme ? 'hidden' : 'visible'};
      }
    }
  `}
  
`;

export {
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  shadow,
  breakpoints,
  GlobalStyle,
};
