import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, hasSellerBox, bg }) => css`
    position: fixed;
    z-index: 10;
    right: 20px;
    top: ${hasSellerBox ? '66px' : '16px'};
    box-shadow: ${`inset 0 0 0 1px ${
      bg || theme.style?.sellerBox?.backgroundColor
    }`};
    background-color: ${theme.color?.secondary?.pure ||
    theme?.style?.backgroundColor};
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 4px 20px 4px 8px;
  `}
`;

export const ClearButton = styled.div`
  ${({ theme, bg }) => css`
    background: ${bg ||
    theme.color?.secondary?.pure ||
    theme.style?.sellerBox?.backgroundColor};
    font-weight: bold;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;
