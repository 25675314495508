import { useRouter } from 'next/router';
import { useMemo } from 'react';

interface Query {
  seller: string;
  source: string;
  accountId?: string;
  route?: string;
  id?: string;
  voucher?: string;
  test?: string;
  [key: string]: string | string[];
}

export function useRouterQuery() {
  const router = useRouter();

  const routerQuery: Query = useMemo(() => {
    const defaultQuery = router.query as Query;

    defaultQuery.source = defaultQuery.source || 'direto';
    defaultQuery.seller = defaultQuery.seller || 'performance';

    return defaultQuery;
  }, [router.query]);

  return { ...routerQuery };
}
