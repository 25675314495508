import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/resolveenem/dark/dark';
import {
  spacing,
  font as globalFontStyles,
  lineHeight,
  border,
  opacity,
  shadow,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
    secondary: Tokens.FontFamilySecondary,
  },
};

export const resolveEnem = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  shadow,
  data: {
    dsProductId: 'resolveenem',
    business: 'aprovatotal',
    productCode: 'aprovatotal-resolve-enem',
    /**
     * @deprecated
     */
    logoFile: 'logo-resolveenem.svg',
    upsellProductName: 'Treinamento Esquadrão de Elite',
    isTermsAndPoliticsAvailable: true,
    isThemeUsingAllDsTokens: true,
    googleTagManagerId: '',
    stepsNumber: 4,
    lang: 'pt-BR',
    isProductFlowsAvailable: true,
    product: {
      name: 'Aprova Total - Resolve Enem',
      businessName: 'Aprova Total - Resolve Enem',
    },
    faqLink: '',
    links: {
      test: {
        domain: 'https://resolveenem.com.br',
        firstAccess: '',
        terms: 'https://resolveenem.com.br/termos-de-uso',
        politics: 'https://resolveenem.com.br/politica-de-privacidade',
      },
      homolog: {
        domain: 'https://resolveenem.com.br',
        firstAccess: '',
        terms: 'https://resolveenem.com.br/termos-de-uso',
        politics: 'https://resolveenem.com.br/politica-de-privacidade',
      },
      production: {
        domain: 'https://resolveenem.com.br',
        firstAccess: '',
        terms: 'https://resolveenem.com.br/termos-de-uso',
        politics: 'https://resolveenem.com.br/politica-de-privacidade',
      },
    },
    payment: {
      currentStep: 3,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'hipercard', name: 'Hipercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners_club', name: 'Diners' },
      ],
      planTypes: {
        yearly: 'yearly',
        recurrency: 'recurrency',
      },
      sendBffPaymentRequest: {
        documentToNFGeneration: true,
      },
      productDsIcon: 'user',
    },
    pix: {
      currentStep: 2,
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: true,
        },
        displayIcon: true,
        displayAccessButton: true,
      },
      upsellOffer: {
        displayHeader: true,
        upsellVIdeoPresentation: false,
        displayUpsellThemeOnOfferCard: true,
      },
    },
  },
  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-resolveenem.svg',
        width: '200px',
      },
      favicon: 'favicon-resolveenem.png',
      loading: 'rolling-resolveenem.svg',
    },
  },
  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 99 99999 9999',
      },
    },
    payment: {
      title: 'Pagamento',
      description:
        'Você está prestes a adquirir o <b class="no-line-break-word">{PRODUCT_NAME}</b>. Escolha a forma de pagamento para concluir sua compra.',
      button: 'Confirmar pagamento',
      labels: {
        installments: 'Selecione o número de parcelas',
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        cpf: 'CPF para emissão da nota fiscal',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
      },
    },
    success: {
      upsell: {
        title: 'Pagamento realizado com sucesso!',
        subtitle:
          '<br/>Você receberá um e-mail com a confirmação de pagamento.<br/><br/>Mas antes veja essa oportunidade que preparamos exclusivamente para você!',
        icon: 'check_circle',
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
      default: {
        title: 'Pagamento realizado com sucesso!',
        subtitle:
          'Acesse a plataforma <b class="no-line-break-word">Aprova Total</b> e conclua o seu cadastro para começar a estudar.<br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Acessar a plataforma',
        },
      },
      successMessageUpsell: {
        title: 'Compra realizada com sucesso!',
        subtitle:
          'Você garantiu o seu acesso ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b> e ao <b class="no-line-break-word">{UPSELL_PRODUCT_NAME}</b> e, em breve, receberá um e-mail com os dados de acesso à plataforma.<br/><br/><br/>',
        icon: 'check_circle',
        button: {
          text: 'Acessar a plataforma',
        },
      },
    },
  },
} as const;
