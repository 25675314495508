import { api } from '../config/base-api';
import type { Business } from 'types';

export interface SendSmsCodeParams {
  leadId: string;
  productCode: string;
  business: Business;
}

export async function sendSmsVerificationCode(
  { leadId, productCode, business }: SendSmsCodeParams,
  isServer?: boolean
): Promise<void> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/lead/${leadId}/phones/send-sms`
    : `/api/v1/lead/${leadId}/phones/send-sms`;

  await api.post<void>(requestUrl, {
    productCode,
    business,
  });

  return;
}
