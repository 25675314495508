import {
  LoggerAdapter,
  InternalLogger,
  AcceptableTags,
  ErrorLevel,
} from './types';

export class Logger implements LoggerAdapter {
  constructor(
    private readonly sentryAdapter: InternalLogger,
    private readonly elasticAdapter: InternalLogger,
    private readonly simpleLoggerAdapter: InternalLogger
  ) {}

  init(): void {
    // sentry initializes automatically and doesn't need to be called
    if (process.env.NODE_ENV !== 'development') {
      this.elasticAdapter.init();
    }
  }

  fatal(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevel.FATAL,
      error,
      additionalTags,
      additionalInfo
    );
    this.sentryAdapter.captureError(
      ErrorLevel.FATAL,
      error,
      additionalTags,
      additionalInfo
    );
    this.simpleLoggerAdapter.captureError(
      ErrorLevel.FATAL,
      error,
      additionalTags,
      additionalInfo
    );
  }

  error(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevel.ERROR,
      error,
      additionalTags,
      additionalInfo
    );
    this.sentryAdapter.captureError(
      ErrorLevel.ERROR,
      error,
      additionalTags,
      additionalInfo
    );
    this.simpleLoggerAdapter.captureError(
      ErrorLevel.ERROR,
      error,
      additionalTags,
      additionalInfo
    );
  }

  warning(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevel.WARNING,
      error,
      additionalTags,
      additionalInfo
    );
    this.simpleLoggerAdapter.captureError(
      ErrorLevel.WARNING,
      error,
      additionalTags,
      additionalInfo
    );
  }

  info(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevel.INFO,
      error,
      additionalTags,
      additionalInfo
    );
    this.simpleLoggerAdapter.captureError(
      ErrorLevel.INFO,
      error,
      additionalTags,
      additionalInfo
    );
  }

  debug(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevel.DEBUG,
      error,
      additionalTags,
      additionalInfo
    );
    this.simpleLoggerAdapter.captureError(
      ErrorLevel.DEBUG,
      error,
      additionalTags,
      additionalInfo
    );
  }
}
