import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import NextNprogress from 'nextjs-progressbar';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';

import { GlobalStyle } from 'themes/global';
import { useCart } from 'hooks/useCart';
import { useWindowSize } from 'hooks/useWindowSize';
import Loading from 'components/loading';
import Tag from 'componentsV2/Tag';
import {
  loadingUpsellUrls,
  urlsWithBackButton,
  urlsWithoutLogo,
  urlsWithoutSellerBox,
  urlsWTP,
} from 'utils/globalFunctions';
import { initTagManager } from 'utils/tagManagerInit';
import BackButton from 'componentsV2/BackButton';
import SellerBox from 'components/sellerBox';
import dynamic from 'next/dynamic';
import useSessionStorage from 'hooks/useSessionStorage';
import setContextDataLead from 'utils/setContextDataLead';
import { useProductFlow } from 'hooks/useProductFlow';
import { useRouterQuery } from 'hooks/useRouterQuery';

const Provider = dynamic(() => import('componentsV2/DS/Provider'), {
  ssr: false,
});

export default function AppRoot({ children, productGTM }) {
  const router = useRouter();
  const routerQuery = useRouterQuery();
  const context = useCart();
  const { currentStep } = useProductFlow();

  const {
    productTheme,
    loading,
    sellerName,
    productFlow,
    rawQuery,
    dsBrand,
    dsMode,
  } = context;

  const { width } = useWindowSize();
  const [hasHomologCookie, setHasHomologCookie] = useState(false);
  const [dataForm] = useSessionStorage('dataForm', null);
  const [dataLead] = useSessionStorage('dataLead', null);

  const isProductFlowsAvailable = productTheme?.data?.isProductFlowsAvailable;

  useEffect(() => {
    if (productGTM || productTheme?.data?.googleTagManagerId) {
      initTagManager({
        googleTagManagerId: productGTM || productTheme.data.googleTagManagerId,
      });
    }

    setHasHomologCookie(Cookies.get('homologacao-cookie'));

    if (isProductFlowsAvailable && productFlow === null) {
      if (router.pathname !== '/generic-error') {
        window.location.href = `/generic-error${rawQuery}`;
      }
    }
  }, []);

  useEffect(() => {
    if (dataForm && dataLead) {
      setContextDataLead(dataLead, dataForm, context);
    }
  }, [dataForm, dataLead, context]);

  if (!productTheme) {
    return null;
  }

  /**
   * IMPORTANTE: Sempre que se desejar usar o source para a logo de uma
   * página pelo checkout-flow, deve-se seguir a estrutura do objeto abaixo.
   * Fazendo isso, a aplicação irá entender que não deve usar o source para a
   * logo que está setado no arquivo de configuração do produto, e irá usar
   * o source que vem do checkout-flow.
   * PS: A lógica acima irá funcionar em todos os pathnames que não estão
   * no array "urlsWithoutLogo"
   */
  const logoByProductFlow = currentStep?.props?.media?.logo;
  const logoWidthByProductFlow = productFlow?.props?.style?.logo?.width;

  const showBackButton = urlsWithBackButton?.includes(Router?.route);
  const showLogo =
    !urlsWithoutLogo?.includes(Router?.route) || !!logoByProductFlow;
  const isWTP = urlsWTP(Router?.route);
  const hideSellerBox = urlsWithoutSellerBox?.includes(Router?.route);

  const localLogoSource = `/static/logo/${
    dsBrand === 'live'
      ? 'logo-wup-live.svg'
      : `${productTheme?.style?.assets?.logo?.file}`
  }`;
  const logoSource = logoByProductFlow || localLogoSource;
  const mobileLogoWidth =
    logoWidthByProductFlow ||
    productTheme?.style?.assets?.logo?.mobileWidth ||
    '110px';
  const logoWidth =
    logoWidthByProductFlow || productTheme?.style?.assets?.logo?.width;
  const logoContainerTopMargin =
    productTheme?.style?.assets?.logo?.logoContainerTopMargin;

  return (
    <div className="container-wrapper" lang={productTheme?.data?.lang}>
      <Head>
        <title>Checkout - {productTheme?.data?.product?.name}</title>
        <link
          rel="icon"
          href={`/static/favicon/${productTheme?.style?.assets?.favicon}`}
        />
      </Head>
      <NextNprogress
        options={{ showSpinner: false }}
        color={productTheme.color.secondary.pure}
        startPosition={0.3}
        stopDelayMs={200}
        height={2}
      />

      <ThemeProvider theme={productTheme}>
        <GlobalStyle whiteColor />

        <Provider brand={dsBrand || 'ms'} mode={dsMode || 'dark'}>
          {!!sellerName &&
            routerQuery.seller !== 'performance' &&
            !hideSellerBox && (
              <SellerBox
                dataCy="@attendance-tag/name-seller"
                isWTP={isWTP || dsBrand === 'live'}
              />
            )}

          {!!hasHomologCookie && (
            <Tag
              hasSellerBox={
                !!sellerName && routerQuery.seller !== 'performance'
              }
              label="Homologação"
              onClick={() => {
                try {
                  Cookies.remove('homologacao-cookie');
                  window.location.href = router.pathname.replace(
                    '&homologacao=on',
                    ''
                  );
                } catch (error) {
                  window.location.href = '/lead';
                }
              }}
              bg={isWTP ? '#055EA6' : null}
            />
          )}

          {showLogo && (
            <div
              className={`logo ${showBackButton ? 'logo-back-button' : ''}`}
              {...(logoContainerTopMargin && {
                style: { marginTop: logoContainerTopMargin },
              })}
            >
              {showBackButton && (
                <BackButton
                  onClick={() => {
                    router.back();
                  }}
                >
                  Voltar
                </BackButton>
              )}
              <img
                src={logoSource}
                style={{
                  width:
                    width < 250
                      ? '110px'
                      : width < 375
                        ? mobileLogoWidth
                        : logoWidth,
                }}
                alt="Logo"
              />
            </div>
          )}
          {loading && (
            <Loading
              isUpsell={
                loadingUpsellUrls.includes(Router.route) || dsBrand === 'live'
              }
            />
          )}
          {children}
        </Provider>
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}
