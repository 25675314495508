import { api } from '../config/base-api';

interface GetProductDataParams {
  planType?: string;
  productId?: string;
  operation?: string;
  voucher?: string;
  email?: string;
  plan?: string;
  referer?: string;
  business?: string;
  route?: string;
  productCode?: string;
  leadId?: string;
}

export interface GetProductDataResponse {
  status: string;
  name: string;
  code: string;
  vimeoVideoId: string | null;
  youtubeVideoId: string | null;
  amount: number;
  amountTax: number;
  standardAmount: number;
  standardTax: number;
  currency: string;
  amountMontly: number;
  planType: string;
  planName: string;
  amountMontlyTax: number;
  installments: {
    id: number;
    value: string;
    tax: string;
  }[];
  source?: string;
  seller?: string;
}

/** @deprecated use getProductDataV2 instead */
export async function getProductData(
  {
    planType,
    productId,
    operation,
    voucher,
    email,
    plan,
    referer,
    business,
    route,
    productCode,
    leadId,
  }: GetProductDataParams,
  isServer?: boolean
): Promise<GetProductDataResponse> {
  const code = productCode || productId;

  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/product/${code}`
    : `/api/v1/product/${code}`;

  const res = await api.get<GetProductDataResponse>(requestUrl, {
    params: {
      planType,
      productId,
      operation,
      voucher,
      email,
      plan,
      referer,
      business,
      route,
      leadId,
    },
  });

  return res.data;
}
