import * as BaseElasticApm from '@elastic/apm-rum';

import projectConfig from 'package.json';
import { InternalLogger, ErrorLevel, AcceptableTags } from '../types';

export class ElasticAdapter implements Omit<InternalLogger, 'setUser'> {
  constructor(private readonly logger = BaseElasticApm) {}

  init(): void {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    this.logger.init({
      serviceName: 'checkout-front',
      serverUrl: process.env.ELASTIC_APM_SERVER_URL,
      environment: process.env.ELASTIC_APM_ENVIRONMENT,
      serviceVersion: projectConfig.version,
    });
  }

  captureError(
    errorLevel: ErrorLevel,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (additionalInfo) {
      this.logger.apm.addLabels({
        ...additionalInfo,
        ...additionalTags,
        errorLevel,
      });
    }

    this.logger.apm.captureError(error);
  }
}
