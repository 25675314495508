import type { GetSubscriptionRenewPlansResponse, RenewPlan } from './types';
import type {
  GetProductDataResponseV2,
  Plan,
  PaymentMethod,
} from 'services/repositories/products/getProductDataV2';

const mapRenewPlanToPlan = (
  renewPlan: RenewPlan & {
    paymentMethods: PaymentMethod[];
  }
): Plan & {
  id: string;
} => ({
  id: renewPlan.id,
  amount: renewPlan.amount,
  amountTax: 0, // Set to 0 or calculate if possible
  standardAmount: renewPlan.standardAmount,
  standardTax: 0, // Set to 0 or calculate if possible
  amountMontly: 0, // Set to 0 or calculate if possible
  amountMontlyTax: 0, // Set to 0 or calculate if possible
  code: renewPlan.code,
  planName: renewPlan.name,
  planType: renewPlan.type,
  currency: renewPlan.currency,
  installments: renewPlan.installments.map((installment) => ({
    id: installment.id,
    value: installment.value,
    standardValue: installment.standardValue,
    tax: installment.tax,
    totalValue: installment.totalValue,
  })),
  paymentMethods: renewPlan.paymentMethods,
});

export const transformRenewPlansToProductData = (
  renewPlansResponse: GetSubscriptionRenewPlansResponse
): GetProductDataResponseV2 => {
  const paymentMethods = renewPlansResponse.availablePaymentMethods.map(
    (paymentMethod) => ({
      type: paymentMethod,
    })
  );

  const plansWithPaymentMethods = renewPlansResponse.plans.map((plan) => ({
    ...plan,
    paymentMethods: paymentMethods,
  }));

  return {
    name: renewPlansResponse.productName,
    code: renewPlansResponse.productCode,
    source: undefined, // default
    seller: undefined, // default
    checkTicketQuantityAvailable: false, // default
    plans: plansWithPaymentMethods.map(mapRenewPlanToPlan),
  };
};
