import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/em/dark/dark';
import {
  spacing,
  font as globalFontStyles,
  lineHeight,
  border,
  opacity,
  shadow,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const euMilitar = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  shadow,
  data: {
    dsProductId: 'em',
    business: 'eumilitar',
    upsellData: {
      name: 'ema',
      theme: null,
    },
    lead: {
      sendBffLeadRequest: {
        route: true,
        planType: false,
      },
    },
    logoFile: 'logo-eu-militar.svg',
    isTermsAndPoliticsAvailable: true,
    isThemeUsingAllDsTokens: true,

    isProductFlowsAvailable: true,
    googleTagManagerId: 'GTM-KXXM78V',
    stepsNumber: 3,
    lang: 'pt-BR',
    product: {
      name: 'Eu Militar',
      businessName: 'Eu Militar',
    },
    video: {
      vimeoVideoId: '799956984',
      youtubeVideoId: '',
    },
    faqLink: 'https://eumilitar.movidesk.com/kb/pt-br/form/5519/',
    links: {
      test: {
        domain: 'https://test-app.eumilitar.com.br',
        firstAccess:
          'https://test-app.eumilitar.com.br/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://eumilitar.com/termosdeuso/',
        politics: 'https://eumilitar.com/politicasdeprivacidade/',
      },
      homolog: {
        domain: 'https://app.eumilitar.com.br',
        firstAccess:
          'https://app.eumilitar.com.br/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://eumilitar.com/termosdeuso/',
        politics: 'https://eumilitar.com/politicasdeprivacidade/',
      },
      production: {
        domain: 'https://app.eumilitar.com.br',
        firstAccess:
          'https://app.eumilitar.com.br/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://eumilitar.com/termosdeuso/',
        politics: 'https://eumilitar.com/politicasdeprivacidade/',
      },
    },
    payment: {
      currentStep: 2,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'hipercard', name: 'Hipercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners_club', name: 'Diners' },
      ],
      sendBffPaymentRequest: {
        documentToNFGeneration: true,
      },
      productDsIcon: 'student',
      whatsAppRedirect: 'https://wa.me/5521997648721',
    },
    pix: {
      currentStep: 2,
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: true,
        },
        displayIcon: false,
        displayAccessButton: true,
      },
      upsellOffer: {
        displayHeader: true,
        upsellVideoPresentation: true,
        displayUpsellThemeOnOfferCard: false,
      },
    },
  },
  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-eu-militar.svg',
        width: '144px',
      },
      favicon: 'favicon-eumilitar.ico',
      loading: 'rolling-em.svg',
    },
  },
  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 41 99999 9999',
      },
    },
    payment: {
      title: 'Pagamento',
      description:
        'Você está prestes a adquirir seu acesso ao <b class="no-line-break-word">{PRODUCT_NAME}</b>. Escolha a forma de pagamento para concluir sua compra.',
      button: 'Confirmar pagamento',
      labels: {
        installments: 'Selecione o número de parcelas',
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        cpf: 'CPF para emissão da nota fiscal',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
      },
    },
    success: {
      upsell: {
        title: 'Pagamento realizado com sucesso!',
        subtitle: 'Aproveite essa oportunidade agora!',
        icon: 'check_circle',
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /><br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
      default: {
        title: 'Pagamento realizado com sucesso!',
        subtitle:
          'Acesse a plataforma <b class="no-line-break-word">Eu Militar</b> e conclua o seu cadastro para começar a estudar.<br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Acessar o Eu Militar',
        },
      },
      successMessage: {
        title: 'Tudo certo!',
        subtitle:
          'Acesse a plataforma <b class="no-line-break-word">Eu Militar</b> e conclua o seu cadastro para começar a estudar.',
        icon: 'check_circle',
      },
      successMessageUpsell: {
        title: 'Tudo certo!',
        subtitle:
          'Acesse a plataforma <b class="no-line-break-word">Eu Militar</b> e conclua o seu cadastro para começar a estudar. <br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Acessar plataforma',
        },
      },
    },
  },
};
