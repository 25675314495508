import { useContext } from 'react';
import { IconIconAttribute } from '@wisereducacao-npm/design-system-core-web/dist/components/icon/types';

import CartContext from 'contexts/CartContext';
import { ProductFlowProps } from 'utils/productFlow';
import { ProductsIds, ThemeColor } from 'themes/generalTypes';
import { ProviderBrandAttribute } from '@wisereducacao-npm/design-system-brands-web/dist/components/provider/types';
import { SellerAccount } from 'types/SellerAccount';
import { Environment } from 'types/Environment';
import { Operation } from 'types';

export interface ProductThemeProps {
  color: ThemeColor;
  data: {
    lang: Operation;
    pix: {
      currentStep: number;
    };
    dsProductId: ProviderBrandAttribute;
    dsMode: 'dark' | 'light';
    stepsNumber: number;
    upsellData: {
      name: string;
      theme: any;
    };
    isProductFlowsAvailable?: boolean;
    payment: {
      currentStep: number;
      sendBffPaymentRequest?: {
        documentToNFGeneration: boolean;
      };
      productDsIcon: string;
      cardCompanies?: Array<{ value: string; name: string }>;
    };
    success?: {
      upsellOffer: {
        displayHeader: boolean;
        upsellVideoPresentation: boolean;
        displayUpsellThemeOnOfferCard: boolean;
      };
      finalSuccess: {
        displayHeader: {
          default: boolean;
          bankslip: boolean;
        };
        displayAccessButton: boolean;
        displayIcon: boolean;
      };
    };
    product: {
      price: {
        productFullPrice: number;
      };
      bffId: string;
      name: string;
      businessName: string;
    };
    productCode: string;
    business: string;
    upsellProductName: string;
    isTermsAndPoliticsAvailable: boolean;
    faqLink: string;
    links: {
      test: {
        domain: string;
        firstAccess: string;
        politics: string;
        terms: string;
      };
      homolog: {
        domain: string;
        firstAccess: string;
        politics: string;
        terms: string;
      };
      production: {
        domain: string;
        firstAccess: string;
        politics: string;
        terms: string;
      };
    };
    logoFile: string;
    multiProfile?: {
      cardThumbnailFile: string;
      shouldUseMonthlyDifference?: boolean;
    };
    isThemeUsingAllDsTokens?: boolean;
  };
  style: {
    assets: {
      logo: {
        file: string;
      };
    };
  };
  text: {
    lead: {
      button: string;
      placeholder: {
        name: string;
        phone: string;
        email: string;
      };
      labels: {
        name: string;
        phone: string;
        email: string;
      };
      description: string;
      text: string;
    };
    payment: {
      title: string;
      description: string;
      upsell50: {
        title: string;
        subtitle: string;
        description: string;
      };
    };
    success: {
      successMessageUpsell: {
        title: string;
        description: string;
        icon: IconIconAttribute;
      };
      successMessage: {
        title: string;
        description: string;
        icon: IconIconAttribute;
      };
    };
  };
}

export interface AppContextProps {
  productFlow: ProductFlowProps;
  allCheckoutFlowsByProductCode: ProductFlowProps[];
  productTheme: ProductThemeProps;
  mainProductTheme: ProductThemeProps;
  /**
   * Esse parâmetro é usado em vendas ativas para saber, pelo id do source, se o
   * deve ou não ser exibido a oferta do live na tela de sucesso de compra do wol
   */
  isLiveEnabled: boolean;
  productBusiness: string;
  productCode: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  _ga: string;
  purchaseToken: string;
  userLeadId: string;
  rawQuery: string;
  area: Operation;
  product: ProductsIds;

  environment: Environment;
  loading: boolean;
  isMozambiqueTheme: boolean;
  sellerAccount: SellerAccount;
  redirectSuccess: string;
  sellerName: string;
  dsMode: string;
  dsBrand: string;
  setUserLeadId: (userLeadId: string) => void;
  setUserName: (userName: string) => void;
  setUserPhone: (userPhone: string) => void;
  setUserEmail: (userEmail: string) => void;
  setDsBrand: (brand: ProviderBrandAttribute) => void;
  setDsMode: (mode: 'dark' | 'light') => void;
  setLoading: (isLoading: boolean) => void;
  setProductTheme: (theme: any) => void;
  setPurchaseToken: (token: string) => void;
  setRedirectSuccess: (redirect: string) => void;
  setSellerName: (sellerName: string) => void;
  setProductFlow: (flow: ProductFlowProps) => void;
}

export function useCart() {
  const cart = useContext(CartContext);
  return cart as AppContextProps;
}
