export default function ReturnFirstCapital(string) {
  return (
    string?.toLowerCase()?.charAt(0)?.toUpperCase() +
    string?.slice(1)?.toLowerCase()
  );
}

export const capitalizeString = (s) => {
  if (typeof s !== 'string') return '';
  return s?.replace(/(\s|^|')([a-z])/gi, (l) => l?.toUpperCase());
};
