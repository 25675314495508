import { api } from '../config/base-api';

interface GetUnitsParams {
  country: string;
  brand: string;
  region: string;
  city: string;
}

export interface GetUnitsResponse {
  id: string;
  salesforceId: string;
  ometznetId: number;
  name: string;
  brand: string;
  phone: string;
  addressLine: string;
  addressNumber: string;
  addressComplement: string;
  dependentLocality: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  priceBookId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export async function getUnits({
  brand,
  country,
  region,
  city,
}: GetUnitsParams): Promise<GetUnitsResponse[]> {
  const res = await api.get<GetUnitsResponse[]>('/api/v1/franchises/units', {
    params: {
      brand,
      country,
      region,
      city,
    },
  });

  return res.data;
}
