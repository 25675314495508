import { api } from '../../config/base-api';

import { Business } from 'types';

export interface GetProductTicketsParams {
  business: Business;
  plan?: string;
}

interface GetProductTicketsResponse {
  quantityAvailable: number;
}

export async function getProductTickets(
  code: string,
  { business, plan }: GetProductTicketsParams,
  isServer?: boolean
): Promise<GetProductTicketsResponse[]> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/product/${code}/tickets`
    : `/api/v1/product/${code}/tickets`;

  const res = await api.get<GetProductTicketsResponse[]>(requestUrl, {
    params: {
      business,
      plan,
    },
  });

  return res.data;
}
