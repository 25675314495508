import { Business, Operation } from 'types';
import { api } from '../config/base-api';

export interface RecoveryLeadParams {
  operation: Operation;
  shuriToken: string;
  business: Business;
  productCode: string;
}

export interface RecoveryLeadResponse {
  business: Business;
  productCode: string;
  id: string;
  name: string;
  email: string;
  phone: string;
  plan: string;
  planType: string;
}

export async function recoveryLead(
  leadId: string,
  { operation, productCode, shuriToken, business }: RecoveryLeadParams,
  isServer?: boolean
): Promise<void> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/lead/${leadId}`
    : `/api/v1/lead/${leadId}`;

  const response = await api.get(requestUrl, {
    params: {
      operation,
      business,
      shuriToken,
      productCode,
    },
  });

  return response.data;
}
