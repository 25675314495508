import { Logger as BaseLogger } from './Logger';
import { ElasticAdapter } from './internal/ElasticAdapter';
import { SentryAdapter } from './internal/SentryAdapter';
import { SimpleLoggerAdapter } from './internal/SimpleLoggerAdapter';

class LoggerProvider {
  private static instance: BaseLogger;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): BaseLogger {
    if (!LoggerProvider.instance) {
      LoggerProvider.instance = new BaseLogger(
        new SentryAdapter(),
        new ElasticAdapter(),
        new SimpleLoggerAdapter()
      );
    }

    return LoggerProvider.instance;
  }
}

export const logger = LoggerProvider.getInstance();
