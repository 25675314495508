import { api } from '../../config/base-api';
import type { MakePaymentV2Params, MakePaymentV2Response } from './types';

export async function makePaymentV2(
  {
    name,
    email,
    amount,
    business,
    captchaToken,
    document,
    operation,
    type,
    voucher,
    paymentMethods,
    sfLeadId,
    phone,
    plan,
    planType,
    productCode,
    seller,
    source,
  }: MakePaymentV2Params,
  isServer?: boolean
): Promise<{
  token: string;
  redirect: string;
  paymentProfileId: string;
}> {
  const requestUrl = isServer
    ? 'http://localhost:3000/api/v2/payment'
    : '/api/v2/payment';

  const res = await api.post<MakePaymentV2Response>(requestUrl, {
    name,
    email,
    amount,
    business,
    captchaToken,
    document,
    operation,
    type,
    voucher,
    paymentMethods,
    phone,
    sfLeadId,
    plan,
    planType,
    productCode,
    seller,
    source,
  });

  const response = {
    token: res.data.token,
    redirect: res.data.redirect,
    paymentProfileId: res.data.paymentProfileIds?.[0],
    route: res.data.route,
  };

  return response;
}
