import * as Sentry from '@sentry/nextjs';

import { InternalLogger, ErrorLevel, AcceptableTags } from '../types';

export class SentryAdapter implements InternalLogger {
  constructor(private readonly logger = Sentry) {}

  private setCustomFingerprintForHttpCalls(
    scope: Sentry.Scope,
    httpData: {
      requestUrl: string;
      requestMethod: string;
      statusCode: string;
    }
  ): void {
    const formattedRequestUrl = httpData.requestUrl.split('?');
    const requestUrlWithoutQueryStr = formattedRequestUrl[0];
    const queryString = formattedRequestUrl[1];

    if (requestUrlWithoutQueryStr) {
      scope.setFingerprint([
        requestUrlWithoutQueryStr,
        httpData.requestMethod,
        String(httpData.statusCode),
      ]);
    }
    if (queryString) {
      scope.setExtra('queryString', queryString);
    }
  }

  captureError(
    errorLevel: ErrorLevel,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    this.logger.withScope((scope) => {
      if (additionalInfo) {
        if (
          additionalInfo.requestUrl &&
          additionalInfo.statusCode &&
          additionalInfo.requestMethod
        ) {
          this.setCustomFingerprintForHttpCalls(scope, {
            requestUrl: additionalInfo.requestUrl as string,
            requestMethod: additionalInfo.requestMethod as string,
            statusCode: String(additionalInfo.statusCode),
          });
        }

        scope.setContext('Additional Info', additionalInfo);
      }

      if (additionalInfo.userEmail) {
        scope.setUser({ email: additionalInfo.userEmail as string });
      }

      if (additionalTags) {
        scope.setTags(additionalTags);
      }

      scope.setExtra('errorLevel', errorLevel);
      scope.setTag('errorLevel', errorLevel);
      scope.setLevel(errorLevel);

      scope.setExtra('isFromHandler', true);

      this.logger.captureException(error);
    });
  }
}
