import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    position: absolute;
    left: 16px;
    width: auto;
    outline: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    background: none;
    border: none;
    font-family: ${theme.font.family.primary};
    color: ${theme.color.neutral.pure};

    svg {
      margin-right: 10px;
      fill: ${theme.color.neutral.pure};
      path {
        fill: ${theme.color.neutral.pure};
      }
    }

    @media (min-width: 426px) {
      left: 30px;
    }

    &:hover {
      opacity: 0.72;
    }
  `}
`;
