import { SellerAccount } from 'types/SellerAccount';
import { api } from '../config/base-api';

interface GetSellerData {
  source: string;
  seller: string;
  operation: string;
  product: string;
}

export interface GetSellerDataResponse {
  success: boolean;
  code: string;
  name?: string | null;
  isLiveEnabled?: boolean | null;
  fullName?: string | null;
  email?: string | null;
  phone?: string | null;
  account?: SellerAccount;
}

/**
 * @description
 * O Seller abaixo é no contexto de um Vendedor. Sendo assim
 * a função abaixo trás os dados de um vendedor.
 */
export async function getSellerData(
  { source, seller, operation, product }: GetSellerData,
  isServer?: boolean
): Promise<GetSellerDataResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/seller/${source}`
    : `/api/v1/seller/${source}`;

  const res = await api.get<GetSellerDataResponse>(requestUrl, {
    params: {
      seller,
      operation,
      product,
    },
  });

  return res.data;
}
