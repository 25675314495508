import React from 'react';
import { Wrapper, Modal, Title, Description, Link } from './styles';

const SessionErrorModal = () => {
  function handleClick() {
    window.location.reload();
  }

  return (
    <Wrapper>
      <Modal>
        <img src="/static/icons/warning.svg" alt="Atenção" />
        <Title>Os cookies do seu navegador estão desativados</Title>
        <Description>Ative e tente novamente.</Description>
        <Link onClick={handleClick}>Tentar novamente</Link>
      </Modal>
    </Wrapper>
  );
};

export default SessionErrorModal;
