import { api } from '../config/base-api';
import type { Business } from 'types';

export interface ValidateSmsVerificationCodeParams {
  leadId: string;
  code: string;
  productCode: string;
  business: Business;
}

export interface ValidateSmsVerificationCodeResponse {
  isOrderTracked: boolean;
}

export async function validateSmsVerificationCode(
  { leadId, code, business, productCode }: ValidateSmsVerificationCodeParams,
  isServer?: boolean
): Promise<ValidateSmsVerificationCodeResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/lead/${leadId}/phones/validate`
    : `/api/v1/lead/${leadId}/phones/validate`;

  const res = await api.post<ValidateSmsVerificationCodeResponse>(requestUrl, {
    code,
    business,
    productCode,
  });

  return res.data;
}
