import { api } from '../../config/base-api';

import { ProductsCodes } from 'themes/generalTypes';
import { ProductFlowProps } from 'utils/productFlow';

interface GetProductConfigResponse {
  id: string;
  name: string;
  productCode: ProductsCodes;
  config: {
    id: string;
    name: string;
    gtm: string;
    /**
     * @description
     * format wol$dark | wol$light
     */
    designSystemId: string;
    checkoutFlow: {
      flows: ProductFlowProps[];
    };
  };
}

export async function getProductConfig(
  code: string,
  isServer: boolean
): Promise<GetProductConfigResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/product/${code}/config`
    : `/api/v1/product/${code}/config`;

  const res = await api.get<GetProductConfigResponse>(requestUrl);

  return res.data;
}
