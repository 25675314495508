import CartContext from 'contexts/CartContext';
import React, { useContext } from 'react';

import { Wrapper } from './styles';

const BackButton = ({ ...props }) => {
  const context = useContext(CartContext);

  const { productTheme } = context;

  return (
    <Wrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill={`${productTheme?.style?.text?.color}`}
      >
        <path
          d="M3.21984 3.33333L5.6065 0.94L4.6665 0L0.666504 4L4.6665 8L5.6065 7.06L3.21984 4.66667H11.3332V3.33333H3.21984Z"
          fill={`${productTheme?.style?.text?.color}`}
        />
      </svg>
      Voltar
    </Wrapper>
  );
};

export default BackButton;
