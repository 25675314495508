import { PriceMode } from 'types/PriceMode';
import { api } from '../../config/base-api';
import type { GetProductDataParamsV2, GetProductDataResponseV2 } from './types';

export async function getProductDataV2(
  {
    code,
    business,
    planTypes,
    plans,
    leadId,
    operation,
    voucher,
    accountId,
    seller,
    source,
    email,
    priceMode = PriceMode.DEFAULT,
  }: GetProductDataParamsV2,
  isServer?: boolean
): Promise<GetProductDataResponseV2> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v2/product/${code}`
    : `/api/v2/product/${code}`;

  const res = await api.get<GetProductDataResponseV2>(requestUrl, {
    params: {
      business,
      planTypes,
      plans,
      leadId,
      operation,
      voucher,
      accountId,
      priceMode,
      seller,
      source,
      email,
    },
  });

  return res.data;
}
