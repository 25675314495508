import React from 'react';
import CartContext from '../contexts/CartContext';
import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

import dynamic from 'next/dynamic';
import router from 'next/router';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const SellerBoxStyle = ({ theme, isUpsell }) => css`
  background-color: ${convertHexToRGBA(
    theme.color.neutral.pure,
    theme.opacity.light
  )};
  text-align: center;
  padding: 14px 0;
  font-family: ${theme.font.family.primary};
  border-bottom: ${theme.border.width.hairline} solid
    ${theme.color.neutral.mediumContrast};

  dsb-typography {
    dsb-typography {
      &::part(typography) {
        color: ${theme.color.secondary.pure};
      }
    }
  }

  p {
    font-size: 14px;
    padding: 0 10px;
    color: ${theme.color.neutral.pure};
    margin: 0;

    strong {
      font-weight: bold;
      color: ${theme.color.secondary.pure};
    }
  }

  ${isUpsell &&
  theme.data?.lang === 'pt-MZ' &&
  css`
    dsb-typography {
      dsb-typography {
        &::part(typography) {
          color: #1f7fcc;
        }
      }
    }

    p {
      strong {
        color: #1f7fcc;
      }
    }
  `}
`;

const SellerBoxStyled = styled.div([SellerBoxStyle]);

export default class SellerBox extends React.Component {
  state;
  constructor(props) {
    super(props);
  }

  static contextType = CartContext;

  render() {
    return (
      <SellerBoxStyled
        data-cy={this.props.dataCy}
        isUpsell={
          router.pathname.includes('upsell') ||
          router.pathname === '/subscription-welcome'
        }
      >
        <DsTypography
          component="paragraph"
          size="small-bold"
          color="neutral-pure"
        >
          {this.context?.productTheme?.text.sellerBox}{' '}
          <DsTypography
            component="paragraph"
            size="small-bold"
            color="secondary-pure"
          >
            {this.context.sellerName}
          </DsTypography>
        </DsTypography>
      </SellerBoxStyled>
    );
  }
}
