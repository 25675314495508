import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/medcof/light/light';

import {
  spacing,
  font as globalFontStyles,
  lineHeight,
  border,
  opacity,
  shadow,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const medcof = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  shadow,
  data: {
    dsProductId: 'medcof',
    dsMode: 'light',
    business: 'medcof',
    productCode: 'medcof-revalida',
    lead: {
      sendBffLeadRequest: {
        route: false,
        planType: true,
      },
    },
    logoFile: 'logo-medcof.svg',
    isThemeUsingAllDsTokens: true,
    isTermsAndPoliticsAvailable: true,

    isProductFlowsAvailable: true,
    googleTagManagerId: '',
    stepsNumber: 3,
    lang: 'pt-BR',
    product: {
      name: 'Medcof',
      businessName: 'MedCof',
    },
    faqLink: 'https://wiseup.com/faq/',
    links: {
      test: {
        domain:
          'https://test-checkout.medcof.com/lead?source=direto&seller=performance&product-slug=medcof-revalida-2024-40',
        firstAccess: 'https://login.medcof.com.br/onboarding?token=${token}',
        terms: 'https://www.grupomedcof.com.br/termos-de-uso/',
        politics: 'https://www.grupomedcof.com.br/privacidade/',
      },
      homolog: {
        domain:
          'checkout.medcof.com/lead?source=direto&seller=performance&product-slug=medcof-revalida-2024-40?homologacao=on',
        firstAccess: 'https://login.medcof.com.br/onboarding?token=${token}',
        terms: 'https://www.grupomedcof.com.br/termos-de-uso/',
        politics: 'https://www.grupomedcof.com.br/privacidade/',
      },
      production: {
        domain:
          'checkout.medcof.com/lead?source=direto&seller=performance&product-slug=medcof-revalida-2024-40',
        firstAccess:
          'checkout.medcof.com/lead?source=direto&seller=performance&product-slug=medcof-revalida-2024-40',
        terms: 'https://www.grupomedcof.com.br/termos-de-uso/',
        politics: 'https://www.grupomedcof.com.br/privacidade/',
      },
    },
    payment: {
      currentStep: 2,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'hipercard', name: 'Hipercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners_club', name: 'Diners' },
      ],
      planTypes: {
        yearly: 'yearly',
        recurrency: 'recurrency',
      },
      sendBffPaymentRequest: {
        documentToNFGeneration: true,
      },
      productDsIcon: 'user',
    },
    pix: {
      currentStep: 3,
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: false,
        },
        displayIcon: true,
        displayAccessButton: false,
      },
      upsellOffer: {
        displayHeader: true,
        upsellVIdeoPresentation: false,
        displayUpsellThemeOnOfferCard: true,
      },
    },
  },
  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-medcof.svg',
        width: '170px',
      },
      favicon: 'favicon-medcof.png',
      loading: 'rolling-medcof.svg',
    },
  },
  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 99 99999 9999',
      },
    },
    payment: {
      title: 'Pagamento',
      description:
        'Você está prestes a adquirir o <b class="no-line-break-word">{PRODUCT_NAME}</b>. Escolha a forma de pagamento para concluir sua compra.',
      button: 'Confirmar pagamento',
      labels: {
        installments: 'Selecione o número de parcelas',
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        cpf: 'CPF para emissão da nota fiscal',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
      },
    },
    success: {
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro. <br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
      default: {
        title: 'Matrícula realizada com sucesso!',
        subtitle: '',
        offerMessage:
          'Mas antes veja essa oportunidade que preparamos exclusivamente para você!',
        icon: 'check_circle',
        button: {
          text: '',
        },
      },
      successMessage: {
        title: 'Tudo certo!',
        subtitle:
          'Você garantiu o seu acesso ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b>',
        icon: 'cicle_check',
      },
      successMessageUpsell: {
        title: 'Tudo certo!',
        subtitle:
          'Você garantiu o seu acesso ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b> e ao <b class="no-line-break-word">{UPSELL_PRODUCT_NAME}</b>. Clique no botão abaixo para concluir seu cadastro de aluno e ter acesso a plataforma.<br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Acessar o {MAIN_PRODUCT_NAME}',
        },
      },
    },
  },
} as const;
