import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/performanc/dark/dark';

import { convertHexToRGBA } from 'utils/convertHexToRgba';
import {
  spacing,
  font as globalFontStyles,
  lineHeight,
  border,
  opacity,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const performancTriade = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    dsProductId: 'performanc',
    business: 'performan-c',
    productCode: 'performan-c-triade-sucesso',
    isTermsAndPoliticsAvailable: true,
    isThemeUsingAllDsTokens: true,

    isProductFlowsAvailable: true,
    googleTagManagerId: '',
    stepsNumber: 3,
    lang: 'pt-BR',
    product: {
      businessName: 'Tríade do Sucesso',
      name: 'Tríade do Sucesso',
    },
    logoFile: 'logo-triade.png',
    faqLink:
      'https://api.whatsapp.com/send?phone=5511930383323&text=Preciso%20de%20um%20suporte',
    links: {
      test: {
        domain: '',
        firstAccess: '',
        terms: 'https://performan-c.com/termos-de-uso/',
        politics: 'https://performan-c.com/politica-de-privacidade/',
      },
      homolog: {
        domain: '',
        firstAccess: '',
        terms: 'https://performan-c.com/termos-de-uso/',
        politics: 'https://performan-c.com/politica-de-privacidade/',
      },
      production: {
        domain: '',
        firstAccess: '',
        terms: 'https://performan-c.com/termos-de-uso/',
        politics: 'https://performan-c.com/politica-de-privacidade/',
      },
    },
    payment: {
      currentStep: 2,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'hipercard', name: 'Hipercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners_club', name: 'Diners' },
      ],
      hasBusinessAndProductCode: true,
      sendBffPaymentRequest: {
        documentToNFGeneration: true,
      },
      productDsIcon: 'user',
    },
    pix: {
      currentStep: 2,
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: false,
        },
        displayIcon: true,
        displayAccessButton: false,
      },
      upsellOffer: {
        displayHeader: true,
        upsellVIdeoPresentation: false,
        displayUpsellThemeOnOfferCard: true,
      },
    },
  },
  style: {
    form: {
      maxWidth: '485px',
    },
    fontFamily: font.family.primary,
    backgroundColor: color.primary.pure,
    assets: {
      logo: {
        file: 'logo-triade.png',
        width: '200px',
      },
      favicon: 'favicon-performanc.ico',
      loading: 'rolling-performanc.svg',
      backgroundLoading: convertHexToRGBA(
        color.primary.pure,
        opacity.semiopaque
      ),
    },
    steps: {
      display: 'none',
      backgroundColor: convertHexToRGBA(color.neutral.pure, opacity.light),
      maxWidth: '485px',
    },
    lead: {
      formTitle: {
        textAlign: 'center',
      },
      checkboxPolitica: {
        boxCheckPolitica: {
          margin: `${spacing.xxxs} 0 ${spacing.xs} 0`,
        },
        textBoxPolitica: {
          color: color.neutral.highContrast,
        },
        checkmarkPolitica: {
          border: `${border.width.thin} solid ${color.neutral.pure}`,
        },
      },
    },
    text: {
      descriptionColor: color.neutral.highContrast,
      color: color.neutral.pure,
      body: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
    sellerBox: {
      backgroundColor: convertHexToRGBA(color.neutral.pure, opacity.light),
      color: color.neutral.pure,
      borderBottom: `${border.width.hairline} solid ${color.neutral.mediumContrast}`,
    },
    link: {
      color: color.secondary.medium,
    },
    pix: {
      buttonColor: color.secondary.pure,
      backgroundBlockError: convertHexToRGBA(
        color.primary.pure,
        opacity.semiopaque
      ),
    },
    buttons: {
      backgroundColor: color.secondary.pure,
    },
    input: {
      width: '100%',
      border: 'none',
      borderBottom: 'none',
      fontWeight: font.weight.regular,
      boxShadow: 'none',
      transition: 'none',
      boxSizing: 'border-box',
      backgroundColor: convertHexToRGBA(color.neutral.pure, opacity.light),
      color: color.neutral.pure,
      fontFamily: font.family.primary,
      borderRadius: border.radius.sm,
      fontSize: font.size.xxs,
      paddingLeft: spacing.xxxs,
      paddingRight: spacing.xxxs,
      paddingBottom: font.size.xxs,
      paddingTop: font.size.xxs,
      padding: `${font.size.xxs} ${spacing.xxxs}`,
      height: spacing.md,

      placeholder: {
        color: convertHexToRGBA(color.neutral.pure, opacity.semiopaque),
      },

      hover: {
        backgroundColor: convertHexToRGBA(color.neutral.pure, opacity.regular),
      },

      focus: {
        borderBottom: 'none',
        paddingLeft: '15px', // input paddingLeft - 1px
        border: `${border.width.hairline} solid ${color.neutral.highContrast}`,
      },

      autofill: {
        border: 'none',
        transition: 'background-color 5000s ease-in-out 0s',
        webkitTextFillColor: color.neutral.pure,
        borderRadius: border.radius.sm,
      },

      invalid: {
        borderBottom: 'none',
        outline: 'none',
        border: `${border.width.hairline} solid ${color.feedback.error.pure}`,
      },

      valid: {
        borderBottom: 'none',
        border: 'none',
        outline: 'none',
      },
    },
    fieldWrapper: {
      position: 'relative',
      width: '100%',
      paddingBottom: spacing.xxxs,
    },
    fieldLabel: {
      display: 'block',
      marginBottom: spacing.insetNano,
    },
    payment: {
      formTitle: {
        textAlign: 'center',
      },
      formSubtitle: {
        fontFamily: font.family.primary,
        fontWeight: font.weight.regular,
        fontSize: font.size.sm,
        textAlign: 'center',
      },
      formText: {
        fontFamily: font.family.primary,
        fontSize: font.size.xxs,
        color: 'red',
        width: '100%',
      },
    },
    success: {
      containerSuccess: {
        marginTop: '10px',
        width: '100%',
      },
      boxSuccess: {
        maxWidth: '300px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      background: {
        paddingTop: '10px',
        image: 'success-back.svg',
        position: '50% 40%',
      },
      button: {
        width: '100%',
        textTransform: 'uppercase',
        letterSpacing: '0',
        fontSize: '14px',
      },
    },
    error: {
      formErrorMsg: {
        margin: `${font.size.sm} 0`,
        fontSize: font.size.xxs,
        paddingTop: border.width.thin,
      },
      errorMsg: {
        display: 'block',
        margin: '0',
        color: color.feedback.error.pure,
        padding: `${spacing.insetNano} 0 0`,
      },
    },
    paymentMethod: {
      float: 'none',
      boxTitle: {
        float: 'left',
        boxSelection: {
          float: 'left',
          ballSelection: {
            display: 'inline-block',
          },
        },
        titlePaymentMethod: {
          float: 'left',
          width: '100%',
          title_1: {
            margin: `${spacing.insetNano} 0 0 0`,
            fontFamily: font.family.primary,
          },
          title_2: {
            margin: '0',
            fontFamily: font.family.primary,
          },
        },
      },
      contentPaymentMethod: {
        float: 'none',
        width: '100%',
        paddingLeft: '0',
        title_1: {
          margin: `${spacing.insetQuarck} 0 ${spacing.insetNano} ${spacing.insetXxs}`,
          opacity: '0.5',
        },
      },
      active: {
        backgroundColor: 'none',
        border: '0',
        boxTitle: {
          boxSelection: {
            ballSelection: {
              background: color.secondary.pure,
            },
          },
        },
        contentPaymentMethod: {
          display: 'block',
        },
      },
    },
    terms: {
      color: color.neutral.pure,
      fontSize: spacing.insetXxs,
      display: 'block',
      width: '100%',
      link: {
        color: color.secondary.pure,
        textDecoration: 'none',
      },
    },
  },
  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 41 99999 9999',
      },
    },
    payment: {
      title: 'Pagamento',
      description:
        'Você está prestes a adquirir o <b class="no-line-break-word">{PRODUCT_NAME}</b>. Escolha a forma de pagamento para concluir sua compra.',
      button: 'Confirmar pagamento',
      labels: {
        installments: 'Selecione o número de parcelas',
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        cpf: 'CPF para emissão da nota fiscal',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
      },
    },
    success: {
      upsell: {
        title: 'Pagamento realizado com sucesso!',
        subtitle:
          '<br/>Você receberá um e-mail com a confirmação de pagamento.<br/><br/>Mas antes veja essa oportunidade que preparamos exclusivamente para você!',
        icon: 'check_circle',
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
      default: {
        title: 'Pagamento realizado com sucesso!',
        subtitle: 'Você receberá um e-mail com a confirmação de pagamento.',
        icon: 'check_circle',
        button: {
          text: '',
        },
      },
      successMessage: {
        title: 'Compra realizada com sucesso!',
        subtitle:
          'Você garantiu o seu acesso ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b> e ao <b class="no-line-break-word">{UPSELL_PRODUCT_NAME}</b> e, em breve, receberá um e-mail com os dados de acesso à plataforma.',
        icon: 'check_circle',
      },
      successMessageUpsell: {
        title: 'Compra realizada com sucesso!',
        subtitle:
          'Você garantiu o seu acesso ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b> e ao <b class="no-line-break-word">{UPSELL_PRODUCT_NAME}</b> e, em breve, receberá um e-mail com os dados de acesso à plataforma.',
        icon: 'check_circle',
      },
    },
  },
} as const;
