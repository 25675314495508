import { Business, Document } from 'types';
import { api } from '../config/base-api';

export interface UpdateLeadDataParams {
  leadId: string;
  document: {
    type: Document;
    value: string;
  };
  birthDate: string;
  relationAccount: string;
  business: Business;
}

export async function updateLeadData({
  birthDate,
  document,
  leadId,
  relationAccount,
  business,
}: UpdateLeadDataParams): Promise<void> {
  await api.patch(`/api/v1/lead/${leadId}`, {
    birthDate,
    document,
    relationAccount,
    business,
  });

  return;
}
