/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { convertHexToRGBA } from 'utils/convertHexToRgba';
import CartContext from '../contexts/CartContext';

const LoadingStyled = styled.div`
  ${({ theme, fixed }) => css`
    ${fixed &&
    css`
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${convertHexToRGBA(
        theme.color.primary.pure,
        theme.opacity.semiopaque
      )};
      z-index: 15;
      cursor: default;
      img {
        position: absolute;
        left: calc(50% - 50px);
        top: 40%;
        z-index: 3;
        width: 100px !important;
        height: 100px !important;
      }
    `}
  `}
`;

const Loading = ({ fixed, isUpsell }) => {
  const { productTheme } = useContext(CartContext);

  return (
    <LoadingStyled id="loading" fixed={fixed}>
      <img
        src={`/static/loading/${
          isUpsell ? 'rolling-upsell.svg' : productTheme.style.assets.loading
        }`}
        alt="Loading..."
      />
    </LoadingStyled>
  );
};

Loading.defaultProps = {
  fixed: true,
  isUpsell: false,
};
Loading.propTypes = {
  fixed: PropTypes.bool,
  isUpsell: PropTypes.bool,
};

export default Loading;
