import { FlagProvider } from '@unleash/proxy-client-react';

const config = {
  url: process.env.UNLEASH_PROXY_CLIENT_URL,
  clientKey: process.env.UNLEASH_PROXY_CLIENT_SECRET,
  refreshInterval: 300,
  appName: 'checkout-front',
  metricsInterval: 300,
  environment: process.env.UNLEASH_PROXY_ENVIRONMENT,
};

export const UnleashProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <FlagProvider config={config}>{children}</FlagProvider>;
};
