import { useRouter } from 'next/router';

import { AppContextProps, useCart } from './useCart';
import { getCurrentStep, getNextStep } from '../utils/productFlow';

export const useProductFlow = () => {
  const context = useCart() as AppContextProps;
  const router = useRouter();

  const currentStep = getCurrentStep({
    productFlow: context?.productFlow,
    pathname: router.pathname,
  });

  const nextStep = getNextStep({
    productFlow: context?.productFlow,
    pathname: router.pathname,
  });

  const productFlow = context.productFlow;

  return { currentStep, nextStep, productFlow };
};
