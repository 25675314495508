import '../styles.scss';

import React from 'react';

import { AppContext, AppProps } from 'next/app';

// context
import { CartProvider } from 'contexts/CartContext';

// services
import { getProductConfig, getSellerData } from 'services/repositories';

// adapters
import { logger } from 'adapters/Logger';

// general configs
import {
  PRODUCTS_AVAILABLE_TO_GET_CONFIG,
  DEFAULT_BUSINESS_BY_PRODUCT,
  DEFAULT_PRODUCT_CODE_BY_PRODUCT,
  formatProductSlugToProductCode,
  convertUrlToFlowId,
  CHECKOUT_CONFIG_BLACKLIST_PAGES,
  SELLER_BLACKLIST_PAGES,
} from 'themes/generalConfigs';

// utils
import {
  getArea,
  getProductByDomainOrProductSlug,
  getRawQuery,
  getEnvironment,
  isMozambiqueTheme,
  getUserGA,
} from 'utils/globalFunctions';
import { isSessionStorageEnabled } from 'utils/getSessionStorageStatus';

// containers
import AppRoot from 'containers/App';
import { UnleashProvider } from 'containers/UnleashProvider';

// components
import SessionErrorModal from 'componentsV2/SessionErrorModal';
import { ErrorBoundary } from 'componentsV2/ErrorBoundary';

logger.init();

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <UnleashProvider>
        <CartProvider pageProps={pageProps}>
          <AppRoot productGTM={pageProps?.checkoutGTM}>
            {isSessionStorageEnabled() ? (
              <Component {...pageProps}></Component>
            ) : null}
            {!isSessionStorageEnabled() && <SessionErrorModal />}
          </AppRoot>
        </CartProvider>
      </UnleashProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async ({ ctx }: AppContext) => {
  const pageProps = {} as any;

  const isServer = !!ctx?.req;

  pageProps.pathname = ctx?.req
    ? new URL(`http://localhost${ctx?.req?.url}`)?.pathname
    : window?.location?.pathname;

  pageProps.host = ctx?.req
    ? ctx?.req?.headers?.domain == undefined
      ? ctx?.req?.headers?.host
      : ctx?.req?.headers?.domain
    : window?.location?.host;
  pageProps.query = ctx?.query;

  pageProps.rawQuery = getRawQuery(pageProps);
  pageProps.arrayQuery = pageProps?.query;
  pageProps.area = getArea(pageProps);

  const splittedHost = pageProps?.host?.toUpperCase().split('.');
  const subdomain = splittedHost?.length > 0 ? splittedHost[0] : '';
  const environment = getEnvironment(subdomain);

  pageProps.environment = environment;
  pageProps.product = getProductByDomainOrProductSlug({
    host: pageProps?.host,
    query: pageProps?.query,
  });
  pageProps.query.seller = pageProps?.query?.seller ?? 'performance';
  pageProps.query.source = pageProps?.query?.source ?? 'direto';
  pageProps.isMozambiqueTheme = isMozambiqueTheme({
    query: pageProps?.query,
  });

  pageProps._ga = getUserGA({
    ctx,
  });
  pageProps.contactId =
    pageProps?.query?.route === 'vip' && pageProps?.query?.contactId
      ? pageProps?.query?.contactId
      : undefined;

  const updatedRawQuery = convertUrlToFlowId(pageProps);
  pageProps.rawQuery = updatedRawQuery;

  const redirectToErrorPage = ({ product }) => {
    ctx?.res
      ?.writeHead(307, {
        Location:
          product === 'WUP'
            ? 'https://wiseup.com/online/error/'
            : `/generic-error${pageProps?.rawQuery}`,
      })
      ?.end();

    return;
  };

  const isPathnameAvailableToGetSeller = !SELLER_BLACKLIST_PAGES.includes(
    pageProps?.pathname
  );

  const shouldCallSellerRequest =
    pageProps?.query?.seller !== 'performance' &&
    isPathnameAvailableToGetSeller &&
    pageProps.query?.source !== 'direto';

  const defaultProductCode =
    DEFAULT_PRODUCT_CODE_BY_PRODUCT[pageProps?.product?.toLowerCase()];
  const defaultBusiness =
    DEFAULT_BUSINESS_BY_PRODUCT[pageProps?.product?.toLowerCase()];

  const { productCode, business } = formatProductSlugToProductCode({
    productSlug: pageProps?.query['product-slug'] || defaultProductCode,
    product: pageProps?.product,
    business: defaultBusiness,
  });

  pageProps.business = business;
  pageProps.productCode = productCode;

  const isPathnameAvailableToGetConfig =
    !CHECKOUT_CONFIG_BLACKLIST_PAGES.includes(pageProps.pathname);

  const shouldGetProductConfig =
    PRODUCTS_AVAILABLE_TO_GET_CONFIG.includes(pageProps?.product) &&
    isPathnameAvailableToGetConfig;

  /**
   * @description
   * Em ambiente local o hot reload afeta a aplicação pois não consegue realizar uma
   * nova consulta da config ao bff. Sendo assim, fizemos essa validação para garantir
   * que não sejamos afetados durante o desenvolvimento
   */
  const isLocalhost = pageProps.host.includes('localhost');

  if ((isServer || isLocalhost) && shouldGetProductConfig) {
    const flowId = pageProps?.query?.flowId || 'default';

    const queryVoucher = pageProps.query.voucher;
    const hasQueryVoucher = !!queryVoucher;

    try {
      const configData = await getProductConfig(productCode, true);
      const checkoutFlow = configData.config.checkoutFlow;

      /**
       * @description
       * Alguns fluxos como pagamento de fatura avulsa não possuem flow
       * mas precisam ter acesso à config no SF para montar algumas coisas como
       * o design system que será usado, então precisamos verificar se existe flow
       * quando buscamos a config
       */
      const productFlow = checkoutFlow
        ? checkoutFlow.flows.find((flow) => flow.id === flowId)
        : null;

      /**
       * @description
       * O Id que é salvo no Salesforce vem no formato:
       * brand$mode, então esperamos esse formato nessa constante
       */
      const [dsBrand, dsMode] = configData.config.designSystemId.split('$');

      pageProps.checkoutGTM = configData.config.gtm;
      pageProps.productFlow = productFlow;
      pageProps.allCheckoutFlowsByProductCode = checkoutFlow?.flows;
      pageProps.dsBrand = dsBrand;
      pageProps.dsMode = dsMode;

      /**
       * @description
       * Setamos o voucher pelo server side para prevenir certos comportamentos indesejados
       * como perder o parametro do voucher por conta do history do navegador
       */
      if (productFlow) {
        const voucherFlow = productFlow.steps[0].props.voucher;

        if (!hasQueryVoucher && !!voucherFlow) {
          pageProps.query.voucher = voucherFlow;
          pageProps.rawQuery += `&voucher=${voucherFlow}`;
        }
      }
    } catch (error) {
      console.error(JSON.stringify(error));

      pageProps.checkoutGTM = null;
      pageProps.productFlow = null;
      pageProps.designSystemId = null;
      redirectToErrorPage({ product: pageProps.product });
    }
  }

  if (isServer && shouldCallSellerRequest) {
    const operation = pageProps?.isMozambiqueTheme ? 'MZ' : 'BR';
    const splittedSource = pageProps?.query?.source?.split('-');
    const source = splittedSource
      ? splittedSource[splittedSource?.length - 1]
      : 'direto';

    try {
      const sellerData = await getSellerData(
        {
          source,
          seller: pageProps?.query?.seller,
          operation,
          product:
            DEFAULT_BUSINESS_BY_PRODUCT[pageProps?.product?.toLowerCase()],
        },
        true
      );

      pageProps.sellerName = sellerData?.name;
      pageProps.isLiveEnabled = sellerData?.isLiveEnabled;
      pageProps.sellerAccount = sellerData.account;
    } catch (err) {
      redirectToErrorPage({ product: pageProps?.product });
    }
  }

  return { pageProps };
};
