var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"em99pAlbdrIFoBTBDljpR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';

import projectConfig from 'package.json';

const IGNORED_ERRORS = [
  'socket hang up',
  'timeout of 30000ms exceeded',
  'Network Error',
  'socket hang up',
  'Non-Error promise rejection captured with value: Timeout',
  'Request failed with status code 400',
  '401 Unauthorized',
  'Request failed with status code 401',
  'Request failed with status code 404',
  '422 Unprocessable Entity',
  'Request failed with status code 422',
  // requests externas (bff out) que geram esses erros
  'The I/O read operation failed.',
  'NotReadableError: The I/O read operation failed.',
  // erro voltado à requests do GTM que dão erro
  'Failed to fetch',
  // erro de estilização do captcha no Safari (captchaV2 | payment & pix)
  `null is not an object (evaluating 'N.style')`,
];

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  normalizeDepth: 10,
  environment: process.env.SENTRY_ENV,
  release: projectConfig.version,
  ignoreErrors: IGNORED_ERRORS,
  tracesSampleRate: 0,
  enableTracing: false,

  beforeSend: (event: Sentry.Event, hint: Sentry.EventHint) => {
    const email = Cookies.get('email');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const originalException = hint.originalException as any;

    event.extra = {
      ...event.extra,
      identifier: email,
      messageException: originalException?.message,
    };

    return event;
  },
});
