import { api } from '../../config/base-api';

export interface SendRefererParams {
  product: string;
  referer: string;
}

export async function sendReferer(
  subscriptionId: string,
  { product, referer }: SendRefererParams,
  isServer?: boolean
): Promise<void> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/subscription/${subscriptionId}/referer`
    : `/api/v1/subscription/${subscriptionId}/referer`;

  await api.post(requestUrl, {
    referer,
    product,
  });
}
