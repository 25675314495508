import { api } from '../config/base-api';

interface GetRegionsParams {
  country: string;
  brand: string;
}

export interface GetRegionsResponse {
  brand: string;
  country: string;
  region: string;
}

export async function getRegions({
  brand,
  country,
}: GetRegionsParams): Promise<GetRegionsResponse[]> {
  const res = await api.get<GetRegionsResponse[]>(
    '/api/v1/franchises/regions',
    {
      params: {
        brand,
        country,
      },
    }
  );

  return res.data;
}
