import React from 'react';

import { ClearButton, Wrapper } from './styles';

export default function Tag({
  hasSellerBox,
  bg = null,
  onClick,
  label = 'Homologação',
}) {
  return (
    <Wrapper hasSellerBox={hasSellerBox} bg={bg}>
      <span>{label}</span>
      <ClearButton bg={bg} onClick={() => !!onClick && onClick()}>
        <img src="/static/icon-close.svg" />
      </ClearButton>
    </Wrapper>
  );
}
