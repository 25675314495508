import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/wol/dark/dark';
import {
  spacing,
  font as globalFontStyles,
  lineHeight,
  border,
  opacity,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const travelMZ = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    business: 'wiseup-online',
    dsProductId: 'wol',

    googleTagManagerId: 'GTM-PVZZP4D',
    lang: 'pt-MZ',
    faqLink: 'https://wiseup.com/faq/',
    refererArray: [
      {
        name: 'Site Wise Up',
        id: 'wiseup-site',
        datacy: '@success/sitewiseup-button',
      },
      {
        name: 'Escola Wise Up',
        id: 'wiseup-escola',
        datacy: '@sucess/escolawiseup-button',
      },
      { name: 'Email', id: 'email', datacy: '@success/email-button' },
      { name: 'Facebook', id: 'facebook', datacy: '@sucess/facebook-button' },
      { name: 'Google', id: 'google', datacy: '@sucess/google-button' },
      {
        name: 'Instagram',
        id: 'instagram',
        datacy: '@sucess/instagram-button',
      },
      { name: 'Youtube', id: 'youtube', datacy: '@sucess/youtube-button' },
      {
        name: 'Através de um representante',
        id: 'representante',
        datacy: '@sucess/representative-button',
      },
      {
        name: 'Flávio Augusto',
        id: 'flavioaugusto',
        datacy: '@sucess/flavioaugusto-button',
      },
      {
        name: 'Daniel David',
        id: 'danieldavid',
        datacy: '@sucess/danieldavid-button',
      },
      {
        name: 'STV',
        id: 'stv',
        datacy: '@sucess/stv-button',
      },
      { name: 'Outro', id: 'outro', datacy: '@sucess/outro-button' },
    ],
    product: {
      bffId: 'wiseup-online',
      name: 'Wise Up Online',
      plans: {
        anualProductId: 'ff8de6bd-899f-424d-a5a8-4d5f2faef794',
        monthlyProductId: '',
      },
      price: {
        productCyclePrice: 1560,
        productFullPrice: 18720,
        productStandardCycle: 1560,
        productStandardPrice: 18720,
      },
    },
    payment: {
      methods: {
        creditcard: {
          active: true,
          name: 'Cartão de Crédito',
          planId: {
            test: 'ff8de6bd-899f-424d-a5a8-4d5f2faef794',
            homolog: 'ff8de6bd-899f-424d-a5a8-4d5f2faef794',
            production: 'ff8de6bd-899f-424d-a5a8-4d5f2faef794',
          },
        },
        debitcard: {
          active: false,
          name: 'Cartão de Débito',
          planId: {
            test: '',
            homolog: '',
            production: '',
          },
        },
        boleto: {
          active: false,
          name: 'Boleto Bancário',
          planId: {
            test: '99d3f7f1-500f-45d2-adb1-35de2d25fe54',
            homolog: '99d3f7f1-500f-45d2-adb1-35de2d25fe54',
            production: '99d3f7f1-500f-45d2-adb1-35de2d25fe54',
          },
        },
      },
      hasInstallments: false,
      installments: [],
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'hipercard', name: 'Hipercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners_club', name: 'Diners' },
      ],
      states: [
        { value: 'AC', name: 'AC' },
        { value: 'AL', name: 'AL' },
        { value: 'AP', name: 'AP' },
        { value: 'AM', name: 'AM' },
        { value: 'BA', name: 'BA' },
        { value: 'CE', name: 'CE' },
        { value: 'DF', name: 'DF' },
        { value: 'ES', name: 'ES' },
        { value: 'GO', name: 'GO' },
        { value: 'MA', name: 'MA' },
        { value: 'MT', name: 'MT' },
        { value: 'MS', name: 'MS' },
        { value: 'MG', name: 'MG' },
        { value: 'PA', name: 'PA' },
        { value: 'PB', name: 'PB' },
        { value: 'PR', name: 'PR' },
        { value: 'PE', name: 'PE' },
        { value: 'PI', name: 'PI' },
        { value: 'RJ', name: 'RJ' },
        { value: 'RN', name: 'RN' },
        { value: 'RS', name: 'RS' },
        { value: 'RO', name: 'RO' },
        { value: 'RR', name: 'RR' },
        { value: 'SC', name: 'SC' },
        { value: 'SP', name: 'SP' },
        { value: 'SE', name: 'SE' },
        { value: 'TO', name: 'TO' },
      ],
    },
    links: {
      test: {
        domain: 'https://test-online.wiseup.com',
        firstAccess:
          'https://test-online.wiseup.com/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://wiseup.com/mz/online/termos-de-servico/',
        politics: 'https://wiseup.com/mz/online/politica-de-privacidade/',
      },
      homolog: {
        domain: 'https://online.wiseup.com',
        firstAccess:
          'https://online.wiseup.com/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://wiseup.com/mz/online/termos-de-servico/',
        politics: 'https://wiseup.com/mz/online/politica-de-privacidade/',
      },
      production: {
        domain: 'https://online.wiseup.com',
        firstAccess:
          'https://online.wiseup.com/set-password?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://wiseup.com/mz/online/termos-de-servico/',
        politics: 'https://wiseup.com/mz/online/politica-de-privacidade/',
      },
    },
  },

  style: {
    form: {
      maxWidth: '465px',
    },
    backgroundColor: '#180a29',
    backgroundGradient: '#2C1D3E',
    ribbonColor: '#00eb84',
    invoiceDetails: {
      borderColor: '#180a29',
      divisorColor: '#180a29',
    },
    link: {
      color: '#00eb84',
      bgColor: '#271042',
    },
    pix: {
      backgroundColor: '#2a004f',
      backgroundColorLogo: '#fff',
      buttonColor: '#00eb84',
    },
    text: {
      color: '#fff',
      subColor: '#62587D',
      descriptionColor: '#bcbcbc',
      fontFamily: "'Nunito', sans-serif",
      title: {
        fontSize: '20px',
        lineHeight: '25px',
      },
      subtitle: {
        fontSize: '14px',
        lineHeight: '25px',
      },
      body: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
    fontFamily: "'Nunito', sans-serif",
    sellerBox: {
      backgroundColor: '#2C1D3E',
      color: '#fff',
      borderBottom: '1px solid #62587D',
    },
    buttons: {
      webkitAppearance: 'none',
      mozAppearance: 'none',
      appearance: 'none',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      oBorderRadius: '8px',
      borderRadius: '8px',
      backgroundColor: '#00eb84',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#2a004f',
      height: '48px',
      display: 'block',
      border: 'none',
      fontFamily: "'Nunito', sans-serif",
      cursor: 'pointer',
      margin: '20px auto 50px',
      width: '100%',
      textTransform: 'none',
      lineHeight: '1.57',
      letterSpacing: '0',
      videoMaxWidth: '465px',
    },
    input: {
      width: '100%',
      border: 'none',
      borderBottom: 'none',
      borderRadius: '8px',
      backgroundColor: '#2C1D3E',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: '"Nunito", sans-serif',
      // transition: 'all .2s ease-in-out', // temporario
      transition: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '14px',
      paddingTop: '14px',
      padding: '14px 16px',
      height: '48px',
      boxSizing: 'border-box',
      active: {
        color: '#180a29',
      },
      code: {
        borderBottom: 'solid 1px #404040',
        height: 'auto !important',
      },
      valid: {
        borderBottom: 'none',
        border: 'none',
        outline: 'none',
      },
      invalid: {
        borderBottom: 'none',
        border: 'none',
        outline: '1px solid #FF598A',
      },
      focus: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #62587d',
      },
      outlined: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #4F4965',
      },
      autofill: {
        border: 'none',
        webkitTextFillColor: '#fff',
        transition: 'background-color 5000s ease-in-out 0s',
        borderRadius: '8px',
      },
    },
    select: {
      width: '100%',
      position: 'relative',
      border: 'none',
      borderBottom: 'none',
      backgroundColor: '#2C1D3E',
      backgroundImage: 'url(/static/arrow-down.png)',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: "'Nunito',sans-serif",
      // transition: 'all .2s ease-in-out', // temporario
      transition: 'none',
      height: '48px',
      padding: '14px 16px',
      appearance: 'none',
      webkitAppearance: 'none',
      mozAppearance: 'none',
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      outline: '2px solid #4f4965',
      focus: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #62587d',
      },
      outlined: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #4F4965',
      },
      placeholder: {
        fontFamily: "'Nunito', sans-serif",
        textTransform: 'none',
        color: '#898399',
      },
      autofill: {
        border: 'none',
        webkitTextFillColor: '#fff',
        webkitBoxShadow: 'none',
        transition: 'background-color .2s ease-in-out',
        borderRadius: '8px',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: '#cb0000',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    fieldWrapper: {
      position: 'relative',
      paddingBottom: '16px',
      width: '100%',
      textAlign: 'left',
    },
    fieldLabel: {
      display: 'block',
      fontWeight: 700,
      position: 'relative',
      margin: '0 0 8px',
      marginBottom: '8px',
      color: '#ffffff',
      letterSpacing: 'normal',
      // transition: 'all 0.3s ease-in-out 0s', // temporario
      transition: 'none',
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: "'Nunito', sans-serif",
      zIndex: 9,
      active: {
        color: '#180a29',
      },
    },
    lead: {
      formTitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: '#ffffff',
      },
      checkboxPolitica: {
        boxCheckPolitica: {
          margin: '15px 0 35px 0',
        },
        textBoxPolitica: {
          color: '#FFFFFF',
          colorLink: '#00EC84',
        },
        checkmarkPolitica: {
          border: '2px solid #FFFFFF',
          backgroundColor: '#00EB84',
        },
      },
    },
    payment: {
      formTitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: '#ffffff',
      },
      formText: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '14px',
        textAlign: 'center',
        color: '#9e9e9e',
        width: '100%',
        margin: '0 auto 24px',
      },
    },
    error: {
      formErrorMsg: {
        margin: '20px 0',
        fontSize: '14px',
        paddingTop: '2px',
        display: 'block',
        color: '#FF598A',
        textAlign: 'center',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: '#FF598A',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    steps: {
      display: 'none',
      color: '#9E9E9E',
      checkColor: '#180a29',
      borderColor: '#00eb84',
      backgroundColor: '#2C1D3E',
      maxWidth: '465px',
    },
    success: {
      maxWidth: '400px',
      containerSuccess: {
        marginTop: '10px',
        width: '100%',
      },
      boxSuccess: {
        maxWidth: '300px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: '50% 40%',
      },
      title: {
        fontSize: '32px',
        lineHeight: '50px',
        fontFamily: "'Nunito', sans-serif",
        width: 'auto',
        margin: '100px auto 0',
        fontWeight: 'bold',
      },
      text: {
        lineHeight: '18px',
        fontSize: '14px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#f16522',
          textDecoration: 'none',
        },
      },
      button: {
        width: '100%',
        textTransform: 'uppercase',
        letterSpacing: '0',
        fontSize: '14px',
      },
    },
    successBoleto: {
      maxWidth: '400px',
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: '26px 16px',
      },
      title: {
        fontSize: '32px',
        lineHeight: '67px',
        width: 'auto',
        margin: '45px auto 0',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '16px',
        color: '#fff',
        width: '275px',
        margin: '10px auto 20px',
        display: 'block',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#f16522',
          textDecoration: 'none',
        },
      },
    },
    paymentMethod: {
      float: 'left',
      width: '100%',
      border: '1px solid #00eb84',
      marginBottom: '30px',
      borderRadius: '20px',
      paddingBottom: '10px',
      boxTitle: {
        float: 'left',
        width: '100%',
        cursor: 'default',
        boxSelection: {
          display: 'none',
          float: 'left',
          width: '16%',
          textAlign: 'center',
          ballSelection: {
            display: 'inline-block',
            marginTop: '20px',
            width: '17px',
            height: '17px',
            border: '1px solid #00eb84',
            borderRadius: '100%',
          },
        },
        titlePaymentMethod: {
          float: 'left',
          width: '100%',
          title_1: {
            margin: '8px 0 0 0',
            fontFamily: "'Nunito', sans-serif",
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: '18px',
            color: '#fff',
            lineHeight: '25px',
          },
          title_2: {
            margin: '0 0 0 0',
            fontFamily: "'Nunito', sans-serif",
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#00eb84',
            lineHeight: '25px',
          },
        },
      },
      contentPaymentMethod: {
        marginTop: '16px',
        float: 'left',
        width: '100%',
        paddingLeft: '0',
        paddingRight: '0',
        display: 'none',
        title_1: {
          margin: '4px 0 8px 10px',
          opacity: '0.5',
          color: '#fff',
          fontSize: '14px',
          textAlign: 'left',
          fontWeight: 'bold',
        },
      },
      active: {
        backgroundColor: 'none',
        border: '0',
        boxTitle: {
          boxSelection: {
            ballSelection: {
              background: '#00eb84',
            },
          },
        },
        contentPaymentMethod: {
          display: 'block',
        },
      },
    },
    parsing: {
      maxWidth: '286px',
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: 'top',
      },
      title: {
        fontSize: '32px',
        lineHeight: '67px',
        width: 'auto',
        margin: '30px auto 0',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '16px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
    },
    terms: {
      color: '#ffffff',
      fontSize: '12px',
      margin: '3vh 0 5vh',
      display: 'block',
      width: '100%',
      textTransform: 'uppercase',
      textAlign: 'center',
      letterSpacing: '2px',
      link: {
        color: '#00eb84',
        textDecoration: 'none',
      },
    },
    assets: {
      logo: {
        file: 'logo-wup.svg',
        width: '153px',
      },
      favicon: 'favicon-wol.ico',
      loading: 'rolling-wp.svg',
      backgroundLoading: '#180a29e6',
    },
  },

  text: {
    sellerBox: 'Atendimento:',
    pix: 'Para efetuar o pagamento pelo pix, siga o passo a passo abaixo. Após o processamento do pagamento, você receberá um  e-mail com a confirmação do valor total de sua assinatura anual, de {CYCLEPRICE}',
    error: {
      general:
        'Ocorreu um erro inesperado, por gentileza tente novamente mais tarde',
      payment:
        'Não conseguimos processar a transação. Faça uma nova tentativa.',
    },
    lead: {
      title: '',
      text: 'Para começarmos, preencha os seus dados pessoais',
      button: 'Continuar',
      toco: {
        title: '',
        text: 'Informe seus dados pessoais',
      },
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Telemóvel',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: nome@mail.com',
        phone: 'Ex: 84 123 1234',
      },
    },
    blackFriday: {
      title: 'Falta pouco pra você começar a estudar',
      text: '<br />Aproveite o voucher de R$120,00 da Black November na sua assinatura anual e comece a estudar agora mesmo.',
      pretext:
        '<br />Assim que você confirmar esta etapa, sua assinatura anual será ativada e você terá acesso a todo conteúdo do Wise Up Online. Aproveite o voucher de R$120,00 da Black November na sua assinatura anual e comece a estudar agora mesmo.',
      price: '',
      postext: '',
    },
    trial: {
      title: 'Escolha um dos métodos de pagamento e conclua o seu pedido',
      text: '',
      pretext:
        'Assim que você confirmar esta etapa, sua assinatura anual será ativada e você terá acesso a todo conteúdo do Wise Up Online.',
      price: '',
      postext: '',
    },

    paymentWtp: {
      title: 'Insira os dados do cartão de crédito',
    },

    payment: {
      bankslip: {
        title: 'Falta pouco pra você começar a estudar.',
        description:
          'Seu boleto será emitido na próxima etapa, assim que enviar seus dados. Após o pagamento, sua assinatura anual será ativada garantindo acesso a todo conteúdo do Wise Up Online.',
        BLACKFRIDAY: {
          text: 'Aproveite o voucher de R$120,00 da Black November na sua assinatura anual e comece a estudar agora mesmo.',
        },
        DEZOFF: {
          text: 'Aproveite o voucher de R$120,00 da Black November na sua assinatura anual e comece a estudar agora mesmo.',
        },
      },
      title: 'Escolha um dos métodos de pagamento e finalize o seu pedido',
      text: '',
      pretext:
        'Assim que você confirmar esta etapa, sua assinatura anual será ativada, com a cobrança de 12 parcelas de {CYCLEPRICE} e você terá acesso a todo conteúdo do Wise Up Online.',
      price: '',
      postext: '',
      button: 'Finalizar',
      buttonCardStep: 'Continuar',
      payWith: 'Pagar com ',
      address: 'Endereço de Cobrança',
      labels: {
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        postalCode: 'CEP',
        addressLine1: 'Rua',
        addressLine2: 'Número',
        addressLine3: 'Complemento',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
        postalCode: 'xxxxx xxxx',
        addressLine1: 'xxxxx',
        addressLine2: 'xxxxx',
        addressLine3: 'xxxxx',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      errorMessage: {
        cardCompany: 'Selecione a bandeira do cartão',
      },
    },
    payment3m: {
      title:
        'Falta pouco para liberar seus 3 meses grátis. Informe o seu cartão para ter acesso',
      text: 'Sua assinatura será ativada automaticamente após este período, com a cobrança em 12 parcelas de {CYCLEPRICE}.',
      pretext:
        'Sua assinatura será ativada automaticamente após este período. A cobrança será de',
      price: '',
      postext:
        'Caso não queira se tornar aluno, antes do fim do período grátis faça sua solicitação em nosso site e seu cancelamento será realizado automaticamente.',
      button: 'Finalizar',
      labels: {
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        postalCode: 'CEP',
        addressLine1: 'Rua',
        addressLine2: 'Número',
        addressLine3: 'Complemento',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
        postalCode: 'xxxxx xxxx',
        addressLine1: 'xxxxx',
        addressLine2: 'xxxxx',
        addressLine3: 'xxxxx',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      errorMessage: {
        cardCompany: 'Selecione a bandeira do cartão',
      },
    },
    success: {
      title: 'Parabéns',
      text: 'Você realizou a sua assinatura',
      button: 'Seguir para configuração de senha',
    },
    successBoleto: {
      title: 'Quase lá',
      text1:
        'Seu boleto será registrado no banco e estará disponível para pagamento em até 2 horas. Você pode acessá-lo abaixo.',
      text2:
        'Em até 2 dias após a confirmação de pagamento, seu acesso será enviado ao seu e-mail.',
      button: 'Ver boleto',
    },
  },
} as const;
