/* eslint-disable no-case-declarations */
/* eslint-disable no-useless-escape */
import * as luhn from 'luhn';
import address from '@sideway/address';

/**
 * @access private
 * @param {string} digits
 * @return number new document CNPJ to test of validation
 */
function calcPositions(digits, positions = 10, sumNumbers = 0) {
  for (let i = 0; i < digits.length; i++) {
    sumNumbers += digits[i] * positions;
    positions--;
    if (positions < 2) positions = 9;
  }

  sumNumbers %= 11;
  sumNumbers = sumNumbers < 2 ? 0 : 11 - sumNumbers;
  const doc = digits + sumNumbers;
  return doc;
}

/**
 * @access private
 * @param {string} value
 * @return bool true if valid or false not valid
 */
function testCnpj(value) {
  const matchCNPJ = value.match(/\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}?/);
  if (matchCNPJ == null) return false;

  const cnpjOrigin = value.replace(/\D/g, '');
  const firstTest = cnpjOrigin.substr(0, 12);
  const firstCalc = calcPositions(firstTest, 5);
  const secondCalc = calcPositions(firstCalc, 6);
  const cnpj = secondCalc;

  if (cnpj === cnpjOrigin) return true;
  return false;
}

/**
 * @access private
 * @param {string} value
 * @return bool true if valid or false not valid
 */
export const testCpf = (value) => {
  const matchCPF = value.match(/\d{3}.\d{3}.\d{3}-\d{2}?/);
  if (matchCPF == null) return false;

  let rest = 0;
  let sum = 0;
  const strCPF = value.replace(/\D/g, '');
  if (
    strCPF === '' ||
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999'
  )
    return false;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(strCPF.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export function inputValidate(type, inputValue, lang = 'pt-BR', length = null) {
  let valid = true;
  let errorMessage = null;

  switch (type) {
    case 'NAME':
      const arrayNome = inputValue.trim().split(' ');
      const matchName = inputValue.match(
        /^([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ']{1,}(\s|$)){2,}$/
      );
      if (arrayNome[0].length > 40) {
        errorMessage =
          lang === 'pt-BR' ? 'Nome muito extenso' : 'Nome muito extenso';
        valid = false;
      }

      if (arrayNome.length < 2) {
        errorMessage =
          lang === 'pt-BR'
            ? 'Preencha com nome e sobrenome.'
            : 'Preencha com nome e sobrenome.';
        valid = false;
      } else if (matchName == null) {
        valid = false;
        errorMessage =
          lang === 'pt-BR' ? 'Insira apenas letras' : 'Insira apenas letras';
      }
      break;

    case 'EMAIL':
      // this.setState({value:inputValue.replace(/ /g,'')})
      const value = inputValue;
      const matchEmail = value.match(
        /^(([^<>'"`*%(()\[\]\\.,;:\s@"]+(\.[^<>'"`*%()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
      );
      const matchAccentEmail = value.match(
        /([àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ])+/
      );

      const prefix = value.substring(0, value.indexOf('@'));
      const posfix = value.substring(
        value.indexOf('@') + 1,
        value.indexOf('.')
      );

      if (
        matchEmail == null ||
        matchAccentEmail != null ||
        prefix.length < 2 ||
        posfix.length < 2 ||
        value.length < 7 ||
        value.length > 80 ||
        !address.email.isValid(value)
      ) {
        valid = false;
        errorMessage =
          lang === 'pt-BR'
            ? 'Insira um email válido.'
            : 'Insira um email válido.';
      }
      break;

    case 'PHONE':
      const hasDDI = inputValue.indexOf('+') > -1;
      if (hasDDI) {
        if (inputValue.length < 6) {
          valid = false;
          errorMessage =
            lang === 'pt-BR'
              ? 'Insira um número válido'
              : 'Insira um número válido';
        }
      } else {
        const matches = inputValue.match(
          /^(([1-9]{2})\s?([9]{1})([0-9]{4})[-]([0-9]{4}))$/
        );
        if (matches == null) {
          valid = false;
          errorMessage =
            lang === 'pt-BR'
              ? 'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, selecione a bandeira no ícone ao lado.'
              : 'Seleccione a bandeira do seu país no ícone ao lado e insira um número de telemóvel válido com DDD';
        }
      }
      break;

    case 'CARD_NUMBER':
      const matcheCardNumber = inputValue.match(
        /(\d{4}\s?\d{4}\s?\d{4}\s?\d{4})|(\d{4}\s?\d{6}\s?\d{5})|(\d{4}\s?\d{6}\s?\d{4})/
      );
      if (!luhn.validate(inputValue) || matcheCardNumber == null) {
        valid = false;
        errorMessage =
          lang === 'pt-BR'
            ? 'Revise o número do cartão.'
            : 'Revise o número do cartão';
      }
      break;

    case 'CARD_EXPIRE_DATE':
      const expNum = inputValue.replace(/\D/g, '');
      const mes = expNum.substring(0, 2);
      let ano = expNum.substring(2, 6);
      const hoje = new Date();
      let mesAtual = hoje.getUTCMonth() + 1;
      const anoAtual = hoje.getUTCFullYear();
      if (mesAtual < 10) mesAtual = `0${mesAtual}`;
      if (ano.length == 2) ano = `20${ano}`;
      if (
        mes > '12' ||
        ano < anoAtual ||
        (ano == anoAtual && mes < mesAtual) ||
        ano > anoAtual + 10
      ) {
        valid = false;
        errorMessage =
          lang === 'pt-BR'
            ? 'Revise a data de validade.'
            : 'Revise a data de validade';
      }
      break;

    case 'REGISTRY':
      const numbers = inputValue.replace(/\D/g, '');

      if (numbers.length === 11 && !testCpf(inputValue)) {
        valid = false;
        errorMessage = 'Insira um CPF válido.';
      } else if (numbers.length === 14 && !testCnpj(inputValue)) {
        errorMessage = 'Insira um CNPJ válido.';
        valid = false;
      } else if (numbers.length !== 14 && numbers.length !== 11) {
        valid = false;
        errorMessage = 'Revise este campo.';
      }
      break;

    case 'CPF':
      if (!inputValue) {
        errorMessage = 'Revise o CPF.';
        valid = false;
      } else if (!testCpf(inputValue)) {
        errorMessage = 'Revise o CPF.';
        valid = false;
      }
      break;

    case 'ENDERECO':
      const matchEnd = inputValue.match(
        /^[a-zA-Z0-9À-ú.]+( [a-zA-Z0-9À-ú.]+)*$/
      );
      if (matchEnd == null) {
        valid = false;
      }
      if (!valid) errorMessage = 'Insira um endereço válido';
      break;

    case 'BAIRRO':
      const matchBairro = inputValue.match(
        /^[a-zA-Z0-9À-ú]+( [a-zA-Z0-9À-ú]+)*$/
      );
      if (matchBairro == null) {
        valid = false;
      }

      if (!valid) errorMessage = 'Insira um bairro válido';
      break;

    case 'ESTADO':
      if (inputValue == '') {
        valid = false;
        errorMessage = 'Insira um estado válido';
      }
      break;

    case 'CIDADE':
      const matchCidade = inputValue.match(
        /^[a-zA-Z0-9À-ú]+( [a-zA-Z0-9À-ú]+)*$/
      );
      if (matchCidade == null) {
        valid = false;
      }

      if (!valid) errorMessage = 'Insira uma cidade válida';
      break;

    case 'PAIS':
      const matchPais = inputValue.match(
        /^[a-zA-Z0-9À-ú]+( [a-zA-Z0-9À-ú]+)*$/
      );
      if (matchPais == null) {
        valid = false;
      }

      if (!valid) errorMessage = 'Insira um pais válido';
      break;

    case 'CEP':
      const matchCEP = inputValue.match(/\d{2}\d{3}-\d{3}?/);
      if (matchCEP == null) {
        valid = false;
      }

      if (!valid) errorMessage = 'Insira um CEP válido';
      break;

    case 'NUMERO':
      if (inputValue == '') {
        valid = false;
        errorMessage = 'Insira um número válido';
      }
      break;

    case 'COMPLEMENTO':
      const matchComp = inputValue.match(
        /^[a-zA-Z0-9À-ú]*( [a-zA-Z0-9À-ú]+)*$/
      );
      if (matchComp == null) {
        valid = false;
      }

      if (!valid) errorMessage = 'Insira um complemento válido';
      break;

    case 'CARD_CVV':
      const matchCVV = inputValue.match(/[0-9][0-9][0-9][0-9]?/);

      /**
       * @todo
       * será ajustado para os demais contextos posteriormente
       * a princípio sanará o WOL para evitar possíveis problemas
       */

      const hasLengthValidation = !!length;
      const isCVVLengthValid = inputValue.length === Number(length);

      if (matchCVV == null || (hasLengthValidation && !isCVVLengthValid)) {
        valid = false;
        errorMessage =
          lang === 'pt-BR'
            ? 'Revise o código de segurança.'
            : 'Revise o código de segurança';
      }
      break;

    case 'CARD_COMPANY':
      if (!inputValue) {
        errorMessage = 'Revise a bandeira do cartão.';
        valid = false;
      }
  }
  return { errorMessage, valid };
}
